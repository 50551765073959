.App {
  text-align: center;
}

* {
  font-family: 'Roboto';
  /* font-family: 'Proxima Nova'; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main-container-expanded {
  margin-top: 80px;
  margin-left: 255px;
  margin-right: 15px;
  animation: smoothContract 190ms;
}
.main-container-sidebar-collapsed {
  margin-top: 100px;
  margin-left: 15px;
  margin-right: 15px;
  animation: smoothExpand 190ms;
}

.MuiToolbar-regular {
  min-height: 64px !important;
}

@keyframes smoothExpand {
  0% {
    margin-left: 250px;
  }
  15% {
    margin-left: 230px;
  }
  25% {
    margin-left: 200px;
  }
  35% {
    margin-left: 170px;
  }
  50% {
    margin-left: 150px;
  }
  65% {
    margin-left: 125px;
  }
  75% {
    margin-left: 100px;
  }
  90% {
    margin-left: 75px;
  }
  100% {
    margin-left: 50px;
  }
}
@keyframes smoothContract {
  0% {
    margin-left: 50px;
  }
  15% {
    margin-left: 75px;
  }
  25% {
    margin-left: 100px;
  }
  35% {
    margin-left: 125px;
  }
  50% {
    margin-left: 150px;
  }
  65% {
    margin-left: 170px;
  }
  75% {
    margin-left: 200px;
  }
  90% {
    margin-left: 230px;
  }
  100% {
    margin-left: 250px;
  }
}
.sidemenu-selected {
  background-color: lightgrey !important;
}
.sidemenu-arrow {
  display: flex;
  justify-content: flex-end;
}
#filter {
  padding: 8px;
}
